import React from 'react';
import styled from "styled-components";
import ReactSelect from 'react-select';

import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";


const Select = styled(ReactSelect)`
    margin-top: 8px;
    height: 50px;
    
    .react-select__control {
        box-shadow: none;
        height: 50px;
        // border: 1px solid red;
        border: ${props => props.hasError? '1px solid red' : '1px solid #CCCCCC'};
    }

    .react-select__option--is-focused {
        color: #000000;
        background-color: #DCDDDD;
    }

    .react-select__menu-list {
        max-height: 220px;
    }
`;

const SelectField = ({
    input,
    meta,
    label,
    helperText,
    ...rest
}) => {
    const hasError = meta.error && meta.touched;
    return (
        <React.Fragment>
            <If condition={label}>
                <InputLabel shrink>{label}</InputLabel>
            </If>
            <Select
                {...input}
                {...rest}
                isSearchable
                isClearable
                hasError={hasError}
                classNamePrefix="react-select"
            />
            <FormHelperText id={`${input.name}-helper-text`} error={hasError}>
              <Choose>
                <When condition={meta.error && meta.touched}>{meta.error}</When>
                <When condition={helperText}>{helperText}</When>
              </Choose>
          </FormHelperText>
        </React.Fragment>  
    )
}

export default SelectField;