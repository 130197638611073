import React, { useState } from 'react';
import styled from "styled-components";
import Creatable from 'react-select/creatable';
import { withAsyncPaginate, AsyncPaginate } from 'react-select-async-paginate';
import InputLabel from "@material-ui/core/InputLabel";
import { Auth } from "aws-amplify";

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../../utils/use-actions';
import { useCreateCompany } from '../../utils/crm/use-company';
import SnackProgress from '../shared/snack-progress';

const AsyncCreatablePaginate = withAsyncPaginate(Creatable);

const AsyncCreatablePaginateSelect = styled(AsyncCreatablePaginate)`
    margin-top: ${props => props.marginTop};

    .css-yk16xz-control {
      height: ${props => props.height};
    }

    .css-1pahdxg-control {
      height: ${props => props.height};
    }

    .css-f0arjc-control {
      height: ${props => props.height};
    }
`;

const AsyncPaginateSelect = styled(AsyncPaginate)`
    margin-top: ${props => props.marginTop};

    .css-yk16xz-control {
      height: ${props => props.height};
    }

    .css-1pahdxg-control {
      height: ${props => props.height};
    }

    .css-f0arjc-control {
      height: ${props => props.height};
    }
`;

const CompanySelectField = ({
    input,
    meta,
    label,
    placeholder,
    isCreateable,
    isClearable,
    isMulti,
    height,
    marginTop,
    ...rest
}) => {
    const [progress, setProgress] = useState(false);
    const { searchCompanysList, getCompanysWithName } = useActions(actions);
    const [token, setToken] = useState(null);
    const createCompany = useCreateCompany();
    const userId = Auth.user.username

    const loadVirtualizedOptions = async (q, page) => {
      const { options, hasMore } = await loadOptions(q, page);
      return {
        options: options,
        hasMore
      }
    }

    const loadOptions = async (search, prevPage) => {

      let searchedOptions = [];
      let hasMore;

      if(!search){
        const data = await getCompanysWithName({ 
          sortDirection: 'ASC',
          type: 'Company',
          limit: 20, 
          nextToken: token
        });
        searchedOptions = data.value.listCompanysByName.items;
        setToken(data.value.listCompanysByName.nextToken);
        hasMore = data.value.listCompanysByName.nextToken;

      }else {
        console.log(search);
        const data = await searchCompanysList({
          limit: 20,
          filter: {
            name: { matchPhrasePrefix: search.toLowerCase()}
          },
          nextToken: token
        });
        searchedOptions = data.value.searchCompanys.items;
        setToken(data.value.searchCompanys.nextToken);
        hasMore = data.value.searchCompanys.nextToken;
      }

      return {
        options: searchedOptions,
        hasMore
      }
    }

    const onCreateOption = React.useCallback(async inputValue => {
      setProgress({
				text: 'Creating company...',
				severity: 'info'
			});
      try{
        const values = { name: inputValue, assignees: [{ id: userId }] };
        const data = await createCompany(values);

        setProgress({
          text: 'Done!',
          severity: 'success'
        });
        input.onChange(data.value);
      }catch(e){
        console.log(e);
        setProgress({
          text: e,
          severity: 'error'
        });
      }

      setTimeout(() => {
				setProgress(false)
			}, 1000);

    }, []);

    return (
        <React.Fragment>
            <If condition={label}>
                <InputLabel shrink>{label}</InputLabel>
            </If>
            <Choose>
              <When condition={isCreateable}>
                  <AsyncCreatablePaginateSelect
                  id="custom-select"
                  {...input}
                  {...rest}
                  placeholder={placeholder}
                  height={height}
                  marginTop={marginTop}
                  SelectComponent={Creatable}
                  onCreateOption={onCreateOption}
                  createOptionPosition="first"
                  allowCreateWhileLoading
                  getNewOptionData={(inputValue, optionLabel) => ({
                    id: optionLabel,
                    name: `Create company "${inputValue}"`,
                    __isNew__: true
                  })}
                  loadOptions={loadVirtualizedOptions}
                  debounceTimeout={1000}
                  isDisabled={progress}
                />
              </When>
              <Otherwise>
                <AsyncPaginateSelect
                    id="custom-select"
                    {...input}
                    {...rest}
                    placeholder={placeholder}
                    isClearable={isClearable}
                    isMulti={isMulti}
                    height={height}
                    marginTop={marginTop}
                    loadOptions={loadVirtualizedOptions}
                    debounceTimeout={1000}
                  />
              </Otherwise>
            </Choose>
            <If condition={progress}>
              <SnackProgress progress={progress} />
            </If>
        </React.Fragment>  
    )
}

CompanySelectField.defaultProps = {
  height: '50px',
  marginTop: '0px'
}

export default CompanySelectField;