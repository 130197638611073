import { useState, useEffect } from "react";
import sortBy from 'lodash/sortBy';
import words from 'lodash/words';

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import reject from 'lodash/reject';

export const useEmployees = () => {
	const { getEmployees } = useActions(actions);
	const [employees, setEmployees] = useState([]);

	const fetchEmployees = async () => {
		try {
			const data = await getEmployees({ limit: 1000 });
			const filteredEmployees = reject(data.value.listEmployees.items, (employee) => {
				return employee.status === 'DISABLED' || employee.jobTitle === 'Panafold Admin';
			});
			setEmployees(filteredEmployees);
		} catch (e) {
			console.log(e);
			setEmployees([]);
		}
	};

	useEffect(() => {
		fetchEmployees();
	}, []);

	return [{ employees }];
};

export const useEmployeesWithLoading = () => {
	const { getEmployees } = useActions(actions);
	const [employees, setEmployees] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchEmployees = async () => {
		try {
			setIsLoading(true);
			const data = await getEmployees({ limit: 1000 });
			setEmployees(data.value.listEmployees.items);
			setIsLoading(false);
		} catch (e) {
			console.log(e);
			setEmployees([]);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchEmployees();
	}, []);

	return [{ employees, isLoading }];
};

// export const useFetchEmployeesWithContacts = () => {
// 	const { getEmployeesWithContacts } = useActions(actions);

// 	return async values => {
// 		// console.log(values)
// 			const data = await getEmployeesWithContacts({ limitEmployees: 1000, limitContacts: 20000, filter: values });
// 			return data;
// 	}
// };

export const useGetEmployee = (employeeId, triggerFetch) => {
	const { getEmployee } = useActions(actions);
	const [employee, setEmployee] = useState(null);
	const fetchEmployee = async () => {
		try {
			const data = await getEmployee({ id: employeeId });
			setEmployee(data.value);
		} catch (e) {
			console.log(e);
			setEmployee(null);
		}
	};
	useEffect(() => { if (employeeId) fetchEmployee() }, [employeeId, triggerFetch]);
	return [{ employee }];
};

export const useGetEmployeeForForm = () => {
	const { getEmployee } = useActions(actions);

  return async (employeeId) => {
    const data = await getEmployee({ id: employeeId });
	  return data.value;
  }
};

export const useGetEmployeeForFormWithLoading = (employeeId) => {
	const { getEmployee } = useActions(actions);
	const [employee, setEmployee] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const fetchEmployee = async () => {
		setIsLoading(true);
		try {
			const data = await getEmployee({ id: employeeId });
			setEmployee(data.value);
			setIsLoading(false);
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
	}
	useEffect(() => {
		fetchEmployee();
	}, []);
	return [{ employee, isLoading }];
  // return async (employeeId) => {
  //   const data = await getEmployee({ id: employeeId });
	//   return data.value;
  // }
};

export const useGetEmployeeByEmail = (userEmail) => {
	const { getEmployees } = useActions(actions);
	const [employee, setEmployee] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const fetchEmployee = async () => {
		setIsLoading(true);
		try {
			const data = await getEmployees({ limit: 1000, filter: { email: { eq: userEmail } } });
			setEmployee(data.value.listEmployees.items[0]);
		} catch (e) {
			console.log(e);
			setEmployee(null);
		}
		setIsLoading(false);
	};
	useEffect(() => {
		fetchEmployee();
	}, []);
	return [{ employee, isLoading }];
};

export const useUpdateEmployee = () => {
  const { updateEmployee } = useActions(actions);

  return async (formFields, userId) => {
    const reportFormData = JSON.stringify({formFields});
    const updatedEmployee = await updateEmployee({ input: {
      id: userId,
      reportFormData: reportFormData
    }});
  };
};

export const getInitials = string =>
    words(string)
        .map(word => word.charAt(0))
        .join('')
        .slice(0, 2);